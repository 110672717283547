import {styled} from '@material-ui/styles';
import {Box} from '@material-ui/core';

export const Wrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '100%',
    width: '100%',
    minHeight: '90%',
});

export const Canvas = styled('canvas')({
    width: '400px',
    minHeight: '600px',
});

export const Input = styled('input')({});

export const Header = styled(Box)({
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
});
