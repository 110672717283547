import {Routes} from '../consts/routes';
import {resolveUrl} from '../utils/url';
import {TokenUtils} from '../utils/token';

// eslint-disable-next-line no-undef
type FetchConfig = RequestInit & {
    data?: Record<string, unknown>;
    withOutToken?: boolean;
};

export const apiFetch = <Data = any>(
    route: Routes,
    {data, method, body, withOutToken, ...rest}: FetchConfig = {},
): Promise<Data> => {
    const headers = new Headers({
        'Content-Type': 'application/json',
        ...(!withOutToken
            ? {Authorization: `Bearer ${TokenUtils.getAuthToken()?.token}`}
            : {}),
    });

    const request = new Request(resolveUrl(route), {
        ...rest,
        method: method || 'GET',
        headers,
        ...(body ? {body} : data ? {body: JSON.stringify(data)} : {}),
    });

    return fetch(request).then((response) => {
        if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
        }
        return response.json();
    });
};
