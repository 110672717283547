import {UserIdentity} from 'ra-core';

import {Routes} from '../consts/routes';

import {apiFetch} from './fetch';

export const fetchLogin = (
    email: string,
    password: string,
): Promise<UserIdentity> => {
    return apiFetch(Routes.Login, {
        method: 'POST',
        withOutToken: true,
        data: {email, password},
    });
};

export const fetchLogout = (): Promise<UserIdentity> => {
    return apiFetch(Routes.Logout);
};
