import {useState} from 'react';
import * as THREE from 'three';

import {SceneRef} from './useScene';
import {Model3dType} from './useModelUrl';
import {loadMtl} from './loadMtl';
import {loadOBJ} from './loadOBJ';

export const useLoadModel = (model: Model3dType | undefined, scene: SceneRef) => {
    const [progress, setProgress] = useState(0);

    const loadModel = async () => {
        if (scene.current && model) {
            const mtl = await loadMtl(model.mtl, model.textures, setProgress);

            const obj = await loadOBJ(model.obj, mtl, ({loaded, total}: any) =>
                setProgress(Math.round((loaded / total) * 95 + 5)),
            );
            obj.translateY(-130);
            scene.current.add(obj);
            obj.traverse((child: any) => {
                if (child instanceof THREE.Mesh) {
                    child.castShadow = true;
                    child.receiveShadow = true;
                    if (child.material.map) {
                        child.material.map.anisotropy = 16;
                    }
                }
            });
        }
    };

    return {
        progress,
        loadModel,
    };
};
