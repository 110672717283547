import {UserIdentity} from 'ra-core';

import {Routes} from '../consts/routes';

import {apiFetch} from './fetch';

export const fetchMe = (): Promise<UserIdentity> => {
    return apiFetch(Routes.Me)
        .then(({id, firstName, lastName}) => {
            return Promise.resolve({id, fullName: `${firstName} ${lastName}`});
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
