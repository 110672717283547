import {DataProvider, fetchUtils} from 'react-admin';
import {stringify} from 'query-string';

import {TokenUtils} from '../utils/token';

const apiUrl = process.env.REACT_APP_API_URL;

const httpClient = fetchUtils.fetchJson;

export const getHeaders = () => {
    const headers = new Headers();

    headers.set('Authorization', `Bearer ${TokenUtils.getAuthToken()?.token}`);

    return headers;
};

const dataProvider: DataProvider = {
    getList: (resource, params) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;

        const query = {
            page: page,
            limit: perPage,
            sort: field,
            order: order,
            ...params.filter,
        };

        const url =
            resource === 'users'
                ? `${apiUrl}/scan/users?${stringify(query)}`
                : `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url, {headers: getHeaders()}).then(({json}) => {
            return {
                data: json.items || json,
                total: json.total || json.length || 0,
            };
        });
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            headers: getHeaders(),
        }).then(({json}) => ({
            data: json,
        })),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({id: params.ids}),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url, {headers: getHeaders()}).then(({json}) => ({
            data: json,
        }));
    },

    getManyReference: (resource, params) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url, {headers: getHeaders()}).then(({headers, json}) => ({
            data: json,
            // @ts-ignore
            total: parseInt(headers.get('X-Total-Count')),
        }));
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
            headers: getHeaders(),
        }).then(({json}) => ({data: json})),

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
            headers: getHeaders(),
        }).then(({json}) => ({data: json}));
    },

    create: (resource, params) => {
        const url =
            resource === 'users' ? `${apiUrl}/create` : `${apiUrl}/${resource}`;

        return httpClient(url, {
            method: 'POST',
            body: JSON.stringify(params.data),
            headers: getHeaders(),
        })
            .then(({json}) => ({
                data: {...params.data, id: json.id},
            }))
            .catch((err) => {
                throw new Error(err);
            });
    },

    delete: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
            headers: getHeaders(),
        }).then(({json}) => ({data: json}));
    },

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            headers: getHeaders(),
        }).then(({json}) => ({data: json}));
    },

    postMany: (url: string, params = {}) => {
        return httpClient(`${apiUrl}/${url}`, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: getHeaders(),
        }).then(({json}) => ({data: json}));
    },

    putMany: (url: string, params = {}) => {
        return httpClient(`${apiUrl}/${url}`, {
            method: 'PUT',
            body: JSON.stringify(params),
            headers: getHeaders(),
        }).then(({json}) => ({data: json}));
    },
};

export default dataProvider;
