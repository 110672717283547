import {Datagrid, TextField, List, FieldProps} from 'react-admin';
import {Check, Close, Camera, Accessibility} from '@material-ui/icons';

import {ScanStatus} from '../../types/user';

import {userFilters, UserActionButtons, BulkButtons} from './Plugins';
import {IconWrapper} from './style';

const UsersList = (props: any) => {
    return (
        <List
            {...props}
            filters={userFilters}
            actions={<UserActionButtons basePath={props.basePath} />}
            bulkActionButtons={<BulkButtons />}
        >
            <Datagrid optimized>
                <TextField source="id" />
                <TextField source="firstName" />
                <TextField source="lastName" />
                <TextField source="email" />
                <BooleandCustomField source="active" label="Confirmed e-mail" />
                <IconField source="status" label="Can be scanned" />
            </Datagrid>
        </List>
    );
};

const IconField = (props: FieldProps) => {
    const scanStatus = props.record?.scanProfile?.status;
    switch (scanStatus) {
        case ScanStatus.ScanReady:
            return (
                <IconWrapper>
                    <Accessibility htmlColor="blue" />
                    {'Scan is ready'}
                </IconWrapper>
            );
        case ScanStatus.Scanned:
            return (
                <IconWrapper>
                    <Camera htmlColor="blue" />
                    {'Already scanned'}
                </IconWrapper>
            );
        case ScanStatus.Ready:
            return (
                <IconWrapper>
                    <Check htmlColor="green" />
                    {'Ready to be scanned'}
                </IconWrapper>
            );
        case ScanStatus.NotReady:
        default:
            return (
                <IconWrapper>
                    <Close htmlColor="red" />
                    {'Ready to be scanned'}
                </IconWrapper>
            );
    }
};

const BooleandCustomField = (props: FieldProps) => {
    const isActive = props.record?.active;
    return <p>{isActive ? 'Yes' : 'No'}</p>;
};

export default UsersList;
