import UserIcon from '@material-ui/icons/People';

import UsersList from './List';
import UserCreate from './Create';

const resource = {
    icon: UserIcon,
    list: UsersList,
    create: UserCreate,
};

export default resource;
