import {useEffect, useRef, useState} from 'react';

import {Model3dType} from './useModelUrl';
import {useCamera} from './useCamera';
import {useScene} from './useScene';
import {useRender} from './useRender';
import {useLoadModel} from './useLoadModel';
import {useControls} from './useControls';

export const useCreateView = (model: Model3dType | undefined) => {
    const [start, setStart] = useState(false);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const camera = useCamera();
    const scene = useScene();
    const controls = useControls(canvasRef.current, camera);
    const {progress, loadModel} = useLoadModel(model, scene);
    const renderer = useRender(canvasRef.current, camera, controls, scene, start);

    useEffect(() => {
        if (canvasRef.current && !start && model) {
            loadModel().then(() => {
                setStart(true);
            });
        }
    }, [start, model]);

    return {canvasRef, progress, renderer};
};
