import * as THREE from 'three';
import {RefObject, useEffect, useRef} from 'react';

export type SceneRef = RefObject<THREE.Scene>;
const ambientLight = new THREE.AmbientLight(0xffffff, 1);

const spotLight = new THREE.SpotLight(0xffffff, 0.5);
spotLight.position.set(0, 200, 200);

spotLight.castShadow = true;
spotLight.shadow.bias = -0.0001;
spotLight.shadow.mapSize.width = 1024 * 4;
spotLight.shadow.mapSize.height = 1024 * 4;

spotLight.shadow.camera.near = 500;
spotLight.shadow.camera.far = 4000;
spotLight.shadow.camera.fov = 30;

export const light = spotLight;
export const useScene = () => {
    const scene = useRef<THREE.Scene>(new THREE.Scene());

    useEffect(() => {
        scene.current.add(ambientLight);
        scene.current.add(spotLight);
        scene.current.background = new THREE.Color('black');

        return () => {
            scene.current.remove(ambientLight);
            scene.current.remove(spotLight);
        };
    }, []);

    return scene;
};
