import * as THREE from 'three';
import {useEffect, useRef} from 'react';

export const useRenderer = (canvas: HTMLCanvasElement | null) => {
    const renderer = useRef<THREE.WebGLRenderer>();

    useEffect(() => {
        if (canvas) {
            renderer.current = new THREE.WebGLRenderer({
                preserveDrawingBuffer: true,
                canvas: canvas,
                alpha: true,
                antialias: true,
            });
        }
    }, [canvas]);

    return renderer;
};
