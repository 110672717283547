import {AuthProvider} from 'react-admin';

import {logInUser, logOutUser} from '../utils/auth';
import {fetchMe} from '../api/me';
import {fetchLogin, fetchLogout} from '../api/auth';
import {TokenUtils} from '../utils/token';
import {fetchToken} from '../api/token';

const authProvider: AuthProvider = {
    login: ({username, password}) => {
        return fetchLogin(username, password)
            .then(({accessToken, refreshToken}) => {
                logInUser(accessToken, refreshToken);
                return Promise.resolve();
            })
            .catch(() => {
                return Promise.reject();
            });
    },
    checkError: async (error) => {
        if (error.status === 401 || error.status === 403) {
            const refreshToken = TokenUtils.getRefreshToken();
            if (refreshToken) {
                const token = await fetchToken();
                TokenUtils.setAuthToken(token);
                return Promise.resolve();
            }
            return Promise.reject({logoutUser: true});
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return TokenUtils.getAuthToken() ? Promise.resolve() : Promise.reject();
    },
    logout: async () => {
        logOutUser();
        if (TokenUtils.getAuthToken()) {
            await fetchLogout();
        }
    },
    getIdentity: async () => {
        try {
            return await fetchMe();
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getPermissions: () => {
        return Promise.resolve('admin');
    },
};

export default authProvider;
