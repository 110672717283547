import {fetchToken} from '../api/token';
import {TokenType} from '../types/token';

import {TokenUtils} from './token';

export const refreshToken = () => {
    return fetchToken()
        .then((token) => {
            TokenUtils.setRefreshToken(token);
            return Promise.resolve();
        })
        .catch((err) => {
            logOutUser();
            console.error(err);
        });
};

let refreshTokenTimeout = 0;

const startRefreshTokenTimer = () => {
    refreshTokenTimeout = window.setTimeout(refreshToken, 5 * 60 * 1000);
};

export const logOutUser = () => {
    TokenUtils.removeTokens();
    clearTimeout(refreshTokenTimeout);
};

export const logInUser = (token: TokenType, refreshToken: TokenType) => {
    TokenUtils.setTokens(token, refreshToken);
    startRefreshTokenTimer();
};
