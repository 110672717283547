import {styled} from '@material-ui/styles';
import {Box} from '@material-ui/core';

const styles = {
    name: {display: 'inline-block'},
    surname: {display: 'inline-block', marginLeft: 32},
    email: {width: 544},
};

export default styles;

export const IconWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
});
