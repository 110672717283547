import {useEffect, useRef} from 'react';

import {useRenderer} from './useRenderer';
import {resizeRenderer} from './resizeRenderer';
import {CameraRef} from './useCamera';
import {ControlsRef} from './useControls';
import {light, SceneRef} from './useScene';

export const useRender = (
    canvas: HTMLCanvasElement | null,
    camera: CameraRef,
    controls: ControlsRef,
    scene: SceneRef,
    start: boolean,
) => {
    const renderer = useRenderer(canvas);
    const animationRef = useRef(0);

    const render = () => {
        if (renderer.current && camera.current && resizeRenderer(renderer.current)) {
            const canvas = renderer.current.domElement;
            camera.current.aspect = canvas.clientWidth / canvas.clientHeight;
            camera.current.updateProjectionMatrix();
        }
        if (controls.current) {
            controls.current.update();
        }
        if (renderer.current && scene.current && camera.current) {
            light.position.set(
                camera.current.position.x + 10,
                camera.current.position.y + 10,
                camera.current.position.z + 10,
            );
            renderer.current.render(scene.current, camera.current);
        }

        animationRef.current = requestAnimationFrame(render);
    };

    useEffect(() => {
        if (start) {
            render();
        }
        return () => {
            window.cancelAnimationFrame(animationRef.current);
        };
    }, [start]);

    return renderer;
};
