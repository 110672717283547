import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {RefObject, useEffect, useRef} from 'react';

import {CameraRef} from './useCamera';

export type ControlsRef = RefObject<OrbitControls | null>;

export const useControls = (canvas: HTMLCanvasElement | null, camera: CameraRef) => {
    const controls = useRef<OrbitControls | null>(null);

    useEffect(() => {
        if (canvas && camera.current) {
            controls.current = new OrbitControls(camera.current, canvas);
            controls.current.target.set(0, 0, 0);
            controls.current.maxPolarAngle = Math.PI / 2;
            controls.current.maxDistance = 700;
            controls.current.minDistance = 140;
            controls.current.update();
        }
    }, [canvas]);

    return controls;
};
