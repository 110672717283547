import './App.css';
import {Admin, Resource} from 'react-admin';
import {Route} from 'react-router';
import './styles/global.style.css';

import users from './components/users';
import {Scanner} from './screens/Scanner';
import {ScanViewer} from './screens/ScanViewer';
import CustomLayout from './components/layout';
import dataProvider from './providers/dataProvider';
import authProvider from './providers/authProvider';

function App() {
    return (
        <Admin
            dataProvider={dataProvider}
            authProvider={authProvider}
            customRoutes={[
                <Route path="/scanner" component={Scanner} />,
                <Route path="/scan-viewer" component={ScanViewer} />,
            ]}
            layout={CustomLayout}
            loading={() => <div>{'loading'}</div>}
        >
            <Resource name="users" options={{label: 'Users'}} {...users} />
        </Admin>
    );
}

export default App;
