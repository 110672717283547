import {ChangeEvent, useState} from 'react';

export type Texture = {
    name: string;
    url: string;
};
export type Model3dType = {
    mtl: string;
    obj: string;
    textures: Texture[];
};

export const useFetchModel = () => {
    const [model, setModel] = useState<Model3dType | undefined>();

    const uploadFiles = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            let m: Model3dType = {
                mtl: '',
                obj: '',
                textures: [],
            };
            [...e.target.files].forEach((file) => {
                if (/\.obj/.test(file.name)) {
                    m = {...m, obj: URL.createObjectURL(file)};
                } else if (/\.mtl/.test(file.name)) {
                    m = {...m, mtl: URL.createObjectURL(file)};
                } else {
                    m = {
                        ...m,
                        textures: [
                            ...m.textures,
                            {name: file.name, url: URL.createObjectURL(file)},
                        ],
                    };
                }
            });
            setModel(m);
        }
    };

    return {
        model,
        uploadFiles,
        reset: () => setModel(undefined),
    };
};
