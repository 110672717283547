export enum ScanStatus {
    NotReady = 'not-ready',
    Ready = 'ready',
    Scanned = 'scanned',
    ScanReady = 'scan-ready',
}

export type UserScanProfileType = {
    scanId: string;
    status: ScanStatus;
};
export type UserType = {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    type: string;
    active: boolean;
    scanProfile: UserScanProfileType;
};

export type UserScanDataType = {
    userId: UserType['id'];
    scanId: UserScanProfileType['scanId'];
};

export type QrScanUserResponse = {
    firstName: string;
    lastName: string;
    userId: number;
    scanId: string;
    status: ScanStatus;
    message: string;
};

export type ManagingActiveResponse = {
    accepted: Array<string>;
    rejected: Array<string>;
    success: Array<string>;
    errors: [
        {
            id: string;
            message: string;
            operation: 'accept' | 'reject';
        },
    ];
};
