export enum Routes {
    Register = 'register',
    Verify = 'verify',
    Login = 'login',
    RefreshToken = 'refresh-token',
    Me = 'me',
    ResetPassword = 'reset-password',
    UpdatePassword = 'update-password',
    Logout = 'logout',
    Create = 'create',
    ScanManage = 'scan/manage',
    ScanUsers = 'scan/users',
    ScanQr = 'scan/qr',
}
