import {TokenType} from '../types/token';

export const TokenUtils = {
    setToken: (type: string, token: TokenType) => {
        localStorage.setItem(type, JSON.stringify(token));
    },
    removeToken: (type: string) => {
        localStorage.removeItem(type);
    },
    getToken: (type: string) => {
        try {
            return JSON.parse(`${localStorage.getItem(type)}`) as TokenType;
        } catch (_e) {
            return undefined;
        }
    },

    setTokens: (token: TokenType, refreshToken: TokenType) => {
        TokenUtils.setToken('refreshToken', refreshToken);
        TokenUtils.setToken('token', token);
    },
    setAuthToken: (token: TokenType) => {
        TokenUtils.setToken('token', token);
    },
    setRefreshToken: (refreshToken: TokenType) => {
        TokenUtils.setToken('refreshToken', refreshToken);
    },

    removeTokens: () => {
        TokenUtils.removeToken('token');
        TokenUtils.removeToken('refreshToken');
    },
    removeAuthToken: () => {
        TokenUtils.removeToken('token');
    },
    removeRefreshToken: () => {
        TokenUtils.removeToken('refreshToken');
    },

    getAuthToken: () => {
        return TokenUtils.getToken('token');
    },
    getRefreshToken: () => {
        return TokenUtils.getToken('refreshToken');
    },
};
