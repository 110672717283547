import {createElement} from 'react';
import {useSelector} from 'react-redux';
import {MenuItemLink, getResources} from 'react-admin';
import {withRouter} from 'react-router';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import MenuList from '@mui/material/MenuList';

const CustomMenu = () => {
    const open = useSelector((state: any) => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);

    return (
        <div className={`menu ${open ? 'menu-opened' : 'menu-closed'}`}>
            <MenuList>
                {resources.map((resource) => (
                    <MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={
                            (resource.options && resource.options.label) ||
                            resource.name
                        }
                        leftIcon={createElement(resource.icon)}
                        sidebarIsOpen={open}
                        {...resource}
                    />
                ))}
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/scanner"
                    primaryText="QR Code Scanner"
                    leftIcon={<QrCodeScannerIcon />}
                    sidebarIsOpen={open}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/scan-viewer"
                    primaryText="Scan viewer"
                    leftIcon={<AccessibilityIcon />}
                    sidebarIsOpen={open}
                />
            </MenuList>
        </div>
    );
};

export default withRouter(CustomMenu);
