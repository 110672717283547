import {useAuthenticated} from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';

import {Model3dType, useFetchModel} from './3d/useModelUrl';
import {useCreateView} from './3d/useCreateView';
import {Canvas, Wrapper, Input, Header} from './styles';

const CView = ({model}: {model: Model3dType}) => {
    const {canvasRef} = useCreateView(model);

    return <Canvas ref={canvasRef} />;
};

export const ScanViewer = () => {
    useAuthenticated();
    const {model, uploadFiles, reset} = useFetchModel();

    return (
        <Wrapper>
            <Header>
                <Input multiple type="file" onChange={uploadFiles} />
                <CloseIcon onClick={reset} />
            </Header>
            {model ? <CView model={model} /> : <Canvas />}
        </Wrapper>
    );
};
