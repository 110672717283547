import {MTLLoader} from 'three/examples/jsm/loaders/MTLLoader';
import * as THREE from 'three';

import {promise} from './promise';
import {Texture} from './useModelUrl';

export const loadMtl = (
    mtlUrl: string,
    textures: Texture[],
    onProgress: (p: number) => void,
) => {
    onProgress(1);
    const manager = new THREE.LoadingManager();
    manager.setURLModifier((url) => {
        return textures.find((t) => url.includes(t.name))?.url || url;
    });

    return promise<MTLLoader.MaterialCreator>((resolve) => {
        const mtlLoader = new MTLLoader(manager);
        onProgress(2);
        mtlLoader.load(mtlUrl, async (mtl) => {
            mtl.preload();
            onProgress(5);
            resolve(mtl);
        });
    });
};
