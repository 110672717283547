import {useCallback, useMemo, useState} from 'react';
import {Button} from '@material-ui/core';
import CropFreeIcon from '@material-ui/icons/CropFree';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ErrorIcon from '@mui/icons-material/Error';
import {useAuthenticated} from 'react-admin';

import {QrScanUserResponse, UserScanDataType} from '../../types/user';
import {QrCodeScanner} from '../../components/qr/QrCodeScanner';

import {Separator, UserDetails, UserWrapper, Wrapper} from './styles';
import useQrScanAction from './useQrScanAction';

export const Scanner = () => {
    useAuthenticated();
    const [user, setUser] = useState<UserScanDataType | QrScanUserResponse | null>(
        null,
    );
    const [active, setActive] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const scanUserQrCode = useQrScanAction();

    const qrCodeScanSuccess = useCallback(async (user: UserScanDataType) => {
        setLoading(true);
        setActive(false);
        try {
            const {json} = await scanUserQrCode(user);
            setUser(json);
        } catch (e) {
            setError((e as Error).message);
        }
        setLoading(false);
    }, []);

    const scanNewUser = useCallback(() => {
        setUser(null);
        setActive(true);
        setLoading(false);
        setError('');
    }, []);

    const userName = useMemo(() => {
        // @ts-ignore
        return user && user.firstName ? `${user.firstName} ${user.lastName}` : '';
    }, [user]);

    return (
        <Wrapper>
            <QrCodeScanner onSuccess={qrCodeScanSuccess} active={active} />
            {!active && (
                <UserWrapper>
                    {loading ? (
                        <HourglassEmptyIcon style={{fontSize: 100}} />
                    ) : error ? (
                        <ErrorIcon style={{color: '#a60b0b', fontSize: 100}} />
                    ) : (
                        <CheckBoxIcon style={{color: '#2c7700', fontSize: 100}} />
                    )}
                    <UserDetails theme={{loading, error}}>
                        {loading && 'Checking...'}
                        {user && (
                            <span>
                                {userName} <br />
                                {'is ready for scan'}
                            </span>
                        )}
                        {error}
                    </UserDetails>
                    {!loading && (
                        <Separator>
                            <Button
                                size="large"
                                variant="contained"
                                startIcon={<CropFreeIcon />}
                                onClick={scanNewUser}
                            >
                                {'Scan Next Qr Code'}
                            </Button>
                        </Separator>
                    )}
                </UserWrapper>
            )}
        </Wrapper>
    );
};
