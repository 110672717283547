import {makeStyles, styled} from '@material-ui/styles';
import {Box} from '@material-ui/core';

export const Wrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
    width: '100%',
    minHeight: '90%',
});
export const VideoWrapper = styled(Box)<{active: boolean}>(({theme: {active}}) => ({
    opacity: active ? 1 : 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '95vw',
    height: 'calc(100vh - 120px)',
    width: '700px',
    position: 'relative',
    overflow: 'hidden',
}));
export const Scanner = styled(Box)({
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.5)',
    height: '100%',
    width: '100%',
    clipPath:
        'polygon(0% 0%, 0% 100%, calc(50% - 150px) 100%, calc(50% - 150px) calc(50% - 150px), calc(50% + 150px) calc(50% - 150px), calc(50% + 150px) calc(50% + 150px), calc(50% - 150px) calc(50% + 150px), calc(50% - 150px) 100%, 100% 100%, 100% 0%)',
});
export const ScannerAnimation = styled(Box)({
    position: 'absolute',
    height: '300px',
    width: '300px',
    border: '1px solid #2196f3',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden',
});

export const ScannerBar = styled(Box)({
    position: 'absolute',
    display: 'block',
    height: 5,
    width: '300px',
    left: 0,
    top: 0,
    backgroundColor: 'rgba(33,150,243,0.5)',
});

export const useStyles = makeStyles(() => ({
    bar: {
        animation: `$animate 3000ms ease`,
        animationIterationCount: 'infinite',
    },

    '@keyframes animate': {
        '0%': {
            transform: 'translateY(0)',
        },
        '50%': {
            transform: 'translateY(300px)',
        },
        '100%': {
            transform: 'translateY(0)',
        },
    },
}));

export const ToggleCamera = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    height: '60px',
    width: '60px',
    left: '50%',
    bottom: '20px',
    transform: 'translateX(-50%)',
    borderRadius: '50%',
    backgroundColor: 'rgba(33,150,243,0.5)',
});

export const UserWrapper = styled(Box)({
    position: 'absolute',
    width: '100%',
    minHeight: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

export const UserDetails = styled(Box)({
    fontSize: '54px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '120px',
});
