import {styled} from '@material-ui/styles';
import {Box} from '@material-ui/core';

export const Wrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
    width: '100%',
    minHeight: '90%',
});

export const UserWrapper = styled(Box)({
    position: 'absolute',
    width: '100%',
    minHeight: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

export const UserDetails = styled(Box)<{error: string; loading: boolean}>(
    ({theme: {error, loading}}) => ({
        color: loading ? 'inherit' : error ? '#a60b0b' : '#2c7700',
        fontSize: '44px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    }),
);

export const Separator = styled(Box)({
    paddingTop: '120px',
});
