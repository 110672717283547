import {Routes} from '../consts/routes';
import {TokenUtils} from '../utils/token';
import {TokenType} from '../types/token';

import {apiFetch} from './fetch';

export const fetchToken = () => {
    return apiFetch<TokenType>(Routes.RefreshToken, {
        withOutToken: true,
        method: 'POST',
        data: {token: TokenUtils.getRefreshToken()?.token},
    });
};
