import {useCallback, useEffect, useState} from 'react';

import {UserScanDataType} from '../../types/user';
import {Routes} from '../../consts/routes';
import {apiFetch} from '../../api/fetch';

export default function useQrScanAction() {
    const [data, setData] = useState(false);

    useEffect(() => {
        setData(true);
    }, [data]);

    return useCallback(({scanId, userId}: UserScanDataType) => {
        return apiFetch(Routes.ScanQr, {
            method: 'POST',
            data: {scanId, userId},
        });
    }, []);
}
