import {Fragment, ReactElement, useState} from 'react';
import {
    Button,
    CreateButton,
    FilterButton,
    ExportButton,
    SelectInput,
    TextInput,
    TopToolbar,
    useDataProvider,
    useNotify,
    useRefresh,
    useUnselectAll,
} from 'react-admin';
import {Check, Close, Accessibility} from '@material-ui/icons';

import {ManagingActiveResponse, ScanStatus} from '../../types/user';
import {Routes} from '../../consts/routes';

export const userFilters = [
    <TextInput label="Filter by any field..." source="q" alwaysOn />,
    <SelectInput
        source="status"
        choices={[
            {id: 'ready', name: 'Ready to be scanned'},
            {id: 'not-ready', name: 'Not ready to be scanned'},
            {id: 'scanned', name: 'Already scanned'},
            {id: 'scan-ready', name: 'User scan is ready'},
        ]}
        label="Scan status"
        className="filter-field"
    />,
];

export const UserActionButtons = ({basePath}: {basePath: string}) => (
    <TopToolbar>
        <FilterButton />
        <CreateButton
            basePath={basePath}
            label="create new user"
            className="MuiButton-contained ml-2"
            variant="contained"
        />
        <ExportButton
            label="export data"
            className="MuiButton-contained ml-2"
            variant="contained"
        />
    </TopToolbar>
);

export const BulkButtons = (props: any) => {
    return (
        <Fragment>
            <ToggleActiveButton
                {...props}
                label="Set scan is ready"
                icon={<Accessibility />}
                value={'users'}
                method={'PUT'}
                url={`${Routes.ScanUsers}?status=${ScanStatus.ScanReady}`}
            />
            <ToggleActiveButton
                {...props}
                label="Set inactive"
                icon={<Close />}
                value={'reject'}
                method={'POST'}
                url={Routes.ScanManage}
                classes="ml-2"
            />
            <ToggleActiveButton
                {...props}
                label="Set active"
                icon={<Check />}
                value={'accept'}
                method={'POST'}
                url={Routes.ScanManage}
                classes="ml-2"
            />
        </Fragment>
    );
};

type ToggleActiveButtonProps = {
    selectedIds: Array<string>;
    value: string;
    label: string;
    method: string;
    icon: ReactElement;
    classes: string;
    url: string;
};
const ToggleActiveButton = ({
    selectedIds,
    value,
    label,
    icon,
    method,
    classes = '',
    url = '',
}: ToggleActiveButtonProps) => {
    const [loading, setLoading] = useState(false);

    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const dataProvider = useDataProvider();

    const updateMany = () => {
        (method === 'PUT' ? dataProvider.putMany : dataProvider.postMany)(url, {
            [value]: selectedIds,
        })
            .then(({data}: {data: ManagingActiveResponse}) => {
                refresh();

                if (
                    data?.accepted?.length > 0 ||
                    data?.rejected?.length > 0 ||
                    data?.success?.length > 0
                ) {
                    notify('Users updated');
                }

                unselectAll('users');
                setLoading(false);

                data.errors.forEach(({id, message}) => {
                    notify(`Error: user ${id} not updated - ${message}`, 'warning');
                });
            })
            .catch(() => {
                notify('Error: users not updated', 'warning');
            });
    };

    return (
        <Button
            label={label}
            disabled={loading}
            onClick={updateMany}
            variant="contained"
            className={classes}
        >
            {icon}
        </Button>
    );
};
