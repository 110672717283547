import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader';
import {Group} from 'three';
import {MTLLoader} from 'three/examples/jsm/loaders/MTLLoader';

import {promise} from './promise';

export const loadOBJ = (
    objUrl: string,
    mtl: MTLLoader.MaterialCreator,
    onProgress: (e: any) => void,
) =>
    promise<Group>((resolve) => {
        const objLoader = new OBJLoader();
        objLoader.setMaterials(mtl);
        objLoader.load(objUrl, resolve, onProgress);
    });
