import QrScanner from 'qr-scanner';
import {useCallback, useEffect, useRef, useState} from 'react';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import qrScannerWorkerSource from '!!raw-loader!../../../../node_modules/qr-scanner/qr-scanner-worker.min.js';

import {UserScanDataType} from '../../../types/user';

import {
    Scanner,
    ScannerAnimation,
    ScannerBar,
    ToggleCamera,
    useStyles,
    VideoWrapper,
} from './styles';

QrScanner.WORKER_PATH = URL.createObjectURL(new Blob([qrScannerWorkerSource]));

type QrScannerProps = {
    onSuccess: (user: UserScanDataType) => void;
    active: boolean;
};

export const QrCodeScanner = ({active, onSuccess}: QrScannerProps) => {
    const cls = useStyles();
    const qrScannerRef = useRef<null | QrScanner>(null);
    const videoRef = useRef<null | HTMLVideoElement>(null);
    const [cameras, setCameras] = useState<Array<QrScanner.Camera>>([]);
    const [activeCamera, setActiveCamera] = useState<QrScanner.Camera | null>(null);

    const findQr = useCallback((code: string) => {
        try {
            const user = JSON.parse(code);
            qrScannerRef.current?.stop();
            onSuccess(user);
        } catch (e) {
            // console.log(e);
        }
    }, []);

    const changeCamera = useCallback(() => {
        const newCamera = cameras.filter(({id}) => id !== activeCamera?.id);
        if (newCamera.length && qrScannerRef.current) {
            setActiveCamera(newCamera[0]);
            qrScannerRef.current?.setCamera(newCamera[0].id);
        }
    }, [activeCamera, cameras]);

    useEffect(() => {
        if (videoRef.current) {
            qrScannerRef.current = new QrScanner(
                videoRef.current,
                findQr,
                console.warn,
            );
            QrScanner.listCameras(false).then((c) => {
                setCameras(c);
                setActiveCamera(c[0]);
            });
        }

        return () => {
            qrScannerRef.current?.destroy();
            qrScannerRef.current = null;
        };
    }, []);

    useEffect(() => {
        if (qrScannerRef.current) {
            if (active) {
                qrScannerRef.current.start();
            } else {
                qrScannerRef.current.stop();
            }
        }
    }, [active]);

    return (
        <VideoWrapper theme={{active}}>
            <video ref={videoRef} style={{width: '100%'}} />
            <Scanner />
            <ScannerAnimation>
                <ScannerBar className={cls.bar} />
            </ScannerAnimation>
            {cameras.length > 1 && (
                <ToggleCamera onClick={changeCamera}>
                    <FlipCameraIosIcon fontSize={'large'} />
                </ToggleCamera>
            )}
        </VideoWrapper>
    );
};
