import * as THREE from 'three';
import {RefObject, useEffect, useRef} from 'react';

const fov = 45;
const aspect = 1; // the canvas default
const near = 0.1;
const far = 1000;

export type CameraRef = RefObject<THREE.PerspectiveCamera | null>;

export const useCamera = () => {
    const camera = useRef<THREE.PerspectiveCamera>(
        new THREE.PerspectiveCamera(fov, aspect, near, far),
    );

    useEffect(() => {
        camera.current.position.set(0, 10, 400);
    }, []);

    return camera;
};
