import {
    Create,
    CreateProps,
    SimpleForm,
    TextInput,
    required,
    email,
    maxLength,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

import styles from './style';

const useStyles = makeStyles(styles);

const UserCreate = (props: CreateProps) => {
    const classes = useStyles(props);

    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput
                    autoFocus
                    source="firstName"
                    formClassName={classes.name}
                    validate={[required(), maxLength(100)]}
                />
                <TextInput
                    source="lastName"
                    formClassName={classes.surname}
                    validate={[required(), maxLength(100)]}
                />
                <TextInput
                    source="email"
                    type="email"
                    formClassName={classes.email}
                    fullWidth
                    validation={{email: true}}
                    validate={[required(), email()]}
                />
            </SimpleForm>
        </Create>
    );
};

export default UserCreate;
